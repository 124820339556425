import React, { useState, useEffect, useRef, useCallback } from 'react';
import Draggable from 'react-draggable';
import '../../styles/apps/_desktop1a.scss'; // Adjust the path based on your project structure

const Desktop1a = () => {
  const [rectangles, setRectangles] = useState([]);
  const initialMousePosition = useRef({ x: 0, y: 0 });
  const wasDragged = useRef(false);
  const [isAdmin, setIsAdmin] = useState(false); // Use state to handle admin status
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (window.isAdmin !== undefined) {
      setIsAdmin(window.isAdmin);
    }
  }, []);

  const fetchPosts = useCallback(() => {
    console.log('Fetching posts...');
  
    const currentLang = window.pll_current_language ? window.pll_current_language : 'en';
    console.log('Current language:', currentLang);
  
    // Modify the fetch request to include the per_page parameter
    fetch(`/wp-json/wp/v2/posts?lang=${currentLang}&per_page=50`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`Error fetching posts: ${response.statusText}`);
        }
        return response.json();
      })
      .then(data => {
        console.log('Fetched posts:', data);
  
        // Filter the data to include only posts in the current language
        const filteredData = data.filter(post => post.acf?.box_language === currentLang);
  
        const formattedData = filteredData.map(post => ({
          id: post.id,
          label: post.title.rendered,
          color: post.acf?.box_color || '#AC8C1A', // Color for the "box"
          x: parseInt(post.acf?.box_position_x, 10) || 0,
          y: parseInt(post.acf?.box_position_y, 10) || 0,
          width: parseInt(post.acf?.box_width, 10) || 100,
          height: parseInt(post.acf?.box_height, 10) || 100,
          link: post.acf?.box_link || '#',
          language: post.acf?.box_language || 'en'
        }));
  
        console.log('Formatted data:', formattedData);
        setRectangles(formattedData);
  
        if (!isAdmin) {
          const storedPositions = JSON.parse(localStorage.getItem('boxPositions'));
          if (storedPositions) {
            const updatedRectangles = formattedData.map(rect => {
              const storedPosition = storedPositions.find(pos => pos.id === rect.id);
              return storedPosition ? { ...rect, x: storedPosition.x, y: storedPosition.y } : rect;
            });
            setRectangles(updatedRectangles);
          }
        }
      })
      .catch(error => console.error('Error fetching posts:', error));
  }, [isAdmin]);
  

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);

  const handleStop = useCallback((e, data, id) => {
    console.log(`handleStop called for box ID: ${id}`);
    const updatedRectangles = rectangles.map(rect =>
      rect.id === id ? { ...rect, x: data.x, y: data.y } : rect
    );
    setRectangles(updatedRectangles);

    if (isAdmin) {
      const rect = updatedRectangles.find(rect => rect.id === id);
      console.log('Updated rect:', rect);

      const payload = {
        acf: {
          box_position_x: rect.x,
          box_position_y: rect.y,
          box_color: rect.color,
          box_width: rect.width,
          box_height: rect.height,
          box_link: rect.link,
        },
      };
      console.log('Saving box position:', payload);

      fetch(`/wp-json/wp/v2/posts/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-WP-Nonce': window.MyApiSettings.nonce,
        },
        body: JSON.stringify(payload),
      })
        .then(response => {
          if (!response.ok) {
            return response.json().then(err => {
              console.error('Error saving box position:', err);
              throw new Error('Network response was not ok');
            });
          }
          return response.json();
        })
        .then(data => {
          console.log('Box position saved:', data);
          fetchPosts(); // Refresh posts to ensure the latest positions are fetched
        })
        .catch(error => console.error('Error saving box position:', error));
    } else {
      localStorage.setItem('boxPositions', JSON.stringify(updatedRectangles));
    }
  }, [rectangles, fetchPosts, isAdmin]);

  const handleBoxClick = (id, e) => {
    console.log(`Box clicked with id: ${id}`);
    const rect = rectangles.find(rect => rect.id === id);
    if (rect && rect.link) {
      window.location.href = rect.link;
    }
  };

  const handleMouseDown = (e) => {
    initialMousePosition.current = { x: e.clientX, y: e.clientY };
    wasDragged.current = false;
    console.log('Mouse down at:', initialMousePosition.current);
  };

  const handleMouseUp = (e, rect) => {
    const distanceMoved = Math.sqrt(
      Math.pow(e.clientX - initialMousePosition.current.x, 2) +
      Math.pow(e.clientY - initialMousePosition.current.y, 2)
    );
    console.log('Mouse up, distance moved:', distanceMoved);
    if (e.button === 0 && distanceMoved < 5) { // Check for left mouse button
      handleBoxClick(rect.id, e);
    } else {
      wasDragged.current = true;
      console.log('Box dragged:', rect.id);
    }
  };

  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    initialMousePosition.current = { x: touch.clientX, y: touch.clientY };
    wasDragged.current = false;
    console.log('Touch start at:', initialMousePosition.current);
  };

  const handleTouchEnd = (e, rect) => {
    const touch = e.changedTouches[0];
    const distanceMoved = Math.sqrt(
      Math.pow(touch.clientX - initialMousePosition.current.x, 2) +
      Math.pow(touch.clientY - initialMousePosition.current.y, 2)
    );
    console.log('Touch end, distance moved:', distanceMoved);
    if (distanceMoved < 5) {
      handleBoxClick(rect.id, e);
    } else {
      wasDragged.current = true;
      console.log('Box dragged:', rect.id);
    }
  };

  const deleteRectangle = (id) => {
    console.log(`Deleting rectangle with id: ${id}`);

    fetch(`/wp-json/wp/v2/posts/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-WP-Nonce': window.MyApiSettings.nonce,
      },
    })
      .then(response => {
        if (!response.ok) {
          return response.json().then(err => {
            console.error('Error deleting rectangle:', err);
            throw new Error('Network response was not ok');
          });
        }
        return response.json();
      })
      .then(data => {
        console.log('Rectangle deleted:', data);
        fetchPosts(); // Refresh posts to ensure the latest data is fetched
      })
      .catch(error => console.error('Error deleting rectangle:', error));
  };

  const addRectangle = () => {
    const newRect = {
      label: 'New Box',
      color: '#AC8C1A', // Default color
      x: 50, // Default position x
      y: 50, // Default position y
      width: 100, // Default width
      height: 100, // Default height
      link: '#', // Default link
    };

    fetch('/wp-json/wp/v2/posts', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-WP-Nonce': window.MyApiSettings.nonce,
      },
      body: JSON.stringify({
        title: newRect.label,
        status: 'publish',
        acf: {
          box_color: newRect.color,
          box_position_x: newRect.x,
          box_position_y: newRect.y,
          box_width: newRect.width,
          box_height: newRect.height,
          box_link: newRect.link,
        },
      }),
    })
      .then(response => {
        if (!response.ok) {
          return response.json().then(err => {
            console.error('Error saving new rectangle:', err);
            throw new Error('Network response was not ok');
          });
        }
        return response.json();
      })
      .then(data => {
        console.log('New rectangle saved:', data);
        const savedRect = {
          id: data.id,
          ...newRect,
          link: data.link, // Update the link to the post URL
        };
        setRectangles(prevRectangles => [...prevRectangles, savedRect]);
      })
      .catch(error => console.error('Error saving new rectangle:', error));
  };

  const resetPositions = () => {
    localStorage.removeItem('boxPositions');
    fetchPosts();
  };

  return (
    <div className="parent-wrapper">
      <div className="desktop">
        {rectangles.map(rect => (
          isMobile ? (
            <div
              key={rect.id}
              className="rectangle"
              style={{
                width: `${rect.width}px`,
                height: `${rect.height}px`,
                backgroundColor: rect.color,
                transition: 'width 0.2s ease, height 0.2s ease, left 0.2s ease, top 0.2s ease',
              }}
            >
              <div
                className="box-hole"
                style={{ width: '100%', height: '100%' }}
                onMouseDown={handleMouseDown}
                onMouseUp={(e) => handleMouseUp(e, rect)}
                onTouchStart={handleTouchStart}
                onTouchEnd={(e) => handleTouchEnd(e, rect)}
              ></div>
              <div
                className="box"
                style={{ width: '100%', height: '100%' }}
                onMouseDown={handleMouseDown}
                onMouseUp={(e) => handleMouseUp(e, rect)}
                onTouchStart={handleTouchStart}
                onTouchEnd={(e) => handleTouchEnd(e, rect)}
              >
                <div className="box-label">{rect.label}</div>
              </div>
              {isAdmin && <button className="delete-button" onClick={() => deleteRectangle(rect.id)}>x</button>}
            </div>
          ) : (
            <Draggable
              key={rect.id}
              position={{ x: rect.x, y: rect.y }}
              onStart={() => wasDragged.current = true}
              onStop={(e, data) => handleStop(e, data, rect.id)}
              bounds="parent"
            >
              <div
                className="rectangle"
                style={{
                  width: `${rect.width}px`,
                  height: `${rect.height}px`,
                  backgroundColor: rect.color,
                  transition: 'width 0.2s ease, height 0.2s ease, left 0.2s ease, top 0.2s ease',
                }}
              >
                <div
                  className="box-hole"
                  style={{ width: '100%', height: '100%' }}
                  onMouseDown={handleMouseDown}
                  onMouseUp={(e) => handleMouseUp(e, rect)}
                  onTouchStart={handleTouchStart}
                  onTouchEnd={(e) => handleTouchEnd(e, rect)}
                ></div>
                <div
                  className="box"
                  style={{ width: '100%', height: '100%' }}
                  onMouseDown={handleMouseDown}
                  onMouseUp={(e) => handleMouseUp(e, rect)}
                  onTouchStart={handleTouchStart}
                  onTouchEnd={(e) => handleTouchEnd(e, rect)}
                >
                  <div className="box-label">{rect.label}</div>
                </div>
                {isAdmin && <button className="delete-button" onClick={() => deleteRectangle(rect.id)}>x</button>}
              </div>
            </Draggable>
          )
        ))}
        <div className="control-panel">
          {isAdmin && (
            <div className="button-wrapper">
              <button className="add-button" onClick={addRectangle}>+</button>
            </div>
          )}
          <div className="button-wrapper">
            <button className="reset-button" onClick={resetPositions}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="rotate-ccw">
                <polyline points="1 4 1 10 7 10"></polyline>
                <path d="M3.51 15a9 9 0 1 0 2.13-9.36L1 10"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Desktop1a;
