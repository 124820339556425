// app.js

// Import core libraries
import React from 'react';
import ReactDOM from 'react-dom';

// Import hot-loader
import { hot } from 'react-hot-loader/root';

// Import your components
import Desktop from './modules/Desktop';
import Desktop1a from './modules/Desktop1a';

// Import roomsApp
import './modules/roomsApp';  // This will include roomsApp in the bundle
import './modules/goBack';

// Mark the root components as hot-exported
const HotDesktop = hot(Desktop);
const HotDesktop1a = hot(Desktop1a);

// Render the components based on the presence of their respective mount points
document.addEventListener('DOMContentLoaded', () => {
  const desktopElement = document.getElementById('Desktop');
  if (desktopElement) {
    ReactDOM.render(<HotDesktop />, desktopElement);
  }

  const desktop1aElement = document.getElementById('Desktop1a');
  if (desktop1aElement) {
    ReactDOM.render(<HotDesktop1a />, desktop1aElement);
  }
});
