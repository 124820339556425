const backButton = document.getElementById('backbutton');
            if (backButton) {
                backButton.addEventListener('click', goBack);
            }

function goBack(defaultUrl) {
    const referrer = document.referrer;
    const currentDomain = window.location.origin;
  
    if (referrer.startsWith(currentDomain)) {
      window.history.back();
    } else {
      window.location.href = defaultUrl;
    }
  }
  
