import React, { useState, useEffect, useRef, useCallback } from 'react';
import Draggable from 'react-draggable';
import '../../styles/apps/_desktop.scss';

const Desktop = () => {
  const [rectangles, setRectangles] = useState([]);
  const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0, id: null });
  const [expandedRectangleId, setExpandedRectangleId] = useState(null);
  const [originalPosition, setOriginalPosition] = useState({});
  const isDragging = useRef(false);
  const lastDragEndTime = useRef(0);
  const clickTimeout = useRef(null);
  const wasDragged = useRef(false);
  const initialMousePosition = useRef({ x: 0, y: 0 });

  const isAdmin = typeof window.isAdmin !== 'undefined' && window.isAdmin;

  useEffect(() => {
    fetch(window.MyApiSettings.root + 'custom/v1/positions', {
      credentials: 'include',
      headers: {
        'X-WP-Nonce': window.MyApiSettings.nonce
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data && Array.isArray(data)) {
        setRectangles(data);
      }
    })
    .catch(error => console.error('Error fetching positions:', error));
  }, []);

  const addRectangle = () => {
    if (!isAdmin) return;
    const newRect = {
      id: Date.now(),
      x: 50,
      y: 50,
      width: 100,
      height: 100,
      color1: '#0000ff',
      color2: '#00ff00',
      label: 'New Box'
    };
    const updatedRectangles = [...rectangles, newRect];
    setRectangles(updatedRectangles);
    saveRectangles(updatedRectangles);
  };

  const handleRename = (id) => {
    if (!isAdmin) return;

    const updatedRectangles = rectangles.map(rect => {
      if (rect.id === id) {
        const newLabel = prompt('Edit label:', rect.label);
        if (newLabel !== null) {
          rect.label = newLabel;
        }
      }
      return rect;
    });
    setRectangles(updatedRectangles);
    saveRectangles(updatedRectangles);
  };

  const deleteRectangle = (id) => {
    if (!isAdmin) return;
    const updatedRectangles = rectangles.filter(rect => rect.id !== id);
    setRectangles(updatedRectangles);
    saveRectangles(updatedRectangles);
  };

  const handleStop = (e, data, id) => {
    if (!isAdmin) return;

    lastDragEndTime.current = Date.now();
    isDragging.current = false;
    if (wasDragged.current) {
      console.log(`Drag stopped: ${id}`);
    }

    updateRectanglePosition(id, data.x, data.y, originalPosition.width, originalPosition.height);
  };

  const handleDrag = (e, data) => {
    if (!wasDragged.current && (Math.abs(data.x - initialMousePosition.current.x) > 5 || Math.abs(data.y - initialMousePosition.current.y) > 5)) {
      wasDragged.current = true;
      isDragging.current = true;
      console.log('Dragging...');
    }
  };

  const handleStart = () => {
    console.log('Drag start initiated');
  };

  const updateRectanglePosition = (id, x, y, width, height) => {
    const updatedRectangles = rectangles.map(rect => 
      rect.id === id ? { ...rect, x, y, width, height } : rect
    );
    setRectangles(updatedRectangles);
    saveRectangles(updatedRectangles);
    setOriginalPosition({ id, x, y, width, height });
  };

  const saveRectangles = useCallback((rectangles) => {
    fetch(window.MyApiSettings.root + 'custom/v1/positions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-WP-Nonce': window.MyApiSettings.nonce
      },
      credentials: 'include',
      body: JSON.stringify({ positions: rectangles })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => console.log('Positions saved:', data))
    .catch(error => console.error('Error saving positions:', error));
  }, []);

  const handleContextMenu = (e, id) => {
    if (!isAdmin) return;
    e.preventDefault();
    setContextMenu({
      visible: true,
      x: e.clientX,
      y: e.clientY,
      id: id
    });
  };

  const handleClick = () => {
    setContextMenu({ visible: false, x: 0, y: 0, id: null });
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const handleBoxClick = (id) => {
    if (wasDragged.current) {
      wasDragged.current = false;
      console.log('Click ignored due to recent drag');
      return;
    }
    console.log(`Box clicked: ${id}`);
    const rect = rectangles.find(r => r.id === id);
    setOriginalPosition({
      id: rect.id,
      x: rect.x,
      y: rect.y,
      width: rect.width,
      height: rect.height,
    });
    clickTimeout.current = setTimeout(() => {
      setExpandedRectangleId(id === expandedRectangleId ? null : id);
      console.log(`Box expanded: ${id}`);
    }, 200);
  };

  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (expandedRectangleId && !e.target.closest('.rectangle')) {
        setExpandedRectangleId(null);
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [expandedRectangleId]);

  const getCenteredPosition = (width, height) => {
    const parentWidth = window.innerWidth; // Use viewport width
    const parentHeight = window.innerHeight; // Use viewport height

    const x = (parentWidth - width) / 2;
    const y = (parentHeight - height) / 2;

    return { x, y };
  };

  return (
    <div className="desktop-wrapper">
      <div className="desktop">
        {isAdmin && <button className="add-button" onClick={addRectangle}>+</button>}
        {rectangles.map(rect => {
          const isExpanded = expandedRectangleId === rect.id;
          const position = isExpanded 
            ? getCenteredPosition(0.8 * window.innerWidth, 0.8 * window.innerHeight) 
            : { x: rect.x, y: rect.y };

          return (
            <Draggable
              key={rect.id}
              position={{ x: position.x, y: position.y }}
              disabled={!isAdmin}
              onStart={handleStart}
              onStop={(e, data) => handleStop(e, data, rect.id)}
              onDrag={handleDrag}
              bounds="parent"
            >
              <div
                className={`rectangle color1-${rect.color1} color2-${rect.color2} ${isExpanded ? 'expanded' : ''}`}
                style={{
                  width: isExpanded ? '80vw' : `${rect.width}px`,
                  height: isExpanded ? '80vh' : `${rect.height}px`,
                  transition: 'width 0.2s ease, height 0.2s ease, left 0.2s ease, top 0.2s ease'
                }}
                onContextMenu={(e) => handleContextMenu(e, rect.id)}
              >
                <div className="box"
                  onMouseDown={(e) => {
                    initialMousePosition.current = { x: e.clientX, y: e.clientY };
                    wasDragged.current = false;
                    console.log('Mouse down', initialMousePosition.current);
                  }}
                  onMouseUp={(e) => {
                    const distanceMoved = Math.sqrt(
                      Math.pow(e.clientX - initialMousePosition.current.x, 2) +
                      Math.pow(e.clientY - initialMousePosition.current.y, 2)
                    );
                    console.log('Mouse up, distance moved:', distanceMoved);
                    if (distanceMoved < 5) {
                      handleBoxClick(rect.id);
                    } else {
                      wasDragged.current = true;
                    }
                  }}
                >
                  <div className="box-top"></div>
                  <div className="box-bottom">
                    <div className="box-label">{rect.label}</div>
                  </div>
                </div>
                {isAdmin && <button className="delete-button" onClick={() => deleteRectangle(rect.id)}>x</button>}
              </div>
            </Draggable>
          );
        })}
        {contextMenu.visible && (
          <div
            className="context-menu"
            style={{ top: `${contextMenu.y}px`, left: `${contextMenu.x}px` }}
          >
            <ul>
              <li onClick={() => handleRename(contextMenu.id)}>Rename</li>
              <li onClick={() => deleteRectangle(contextMenu.id)}>Delete</li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Desktop;

